
require.register("datatables.net-rowgroup-dt/js/rowGroup.dataTables.js", function(exports, require, module) {
  require = __makeRelativeRequire(require, {}, "datatables.net-rowgroup-dt");
  (function() {
    /*! DataTables styling wrapper for RowGroup
 * ©2018 SpryMedia Ltd - datatables.net/license
 */

(function( factory ){
	if ( typeof define === 'function' && define.amd ) {
		// AMD
		define( ['jquery', 'datatables.net-dt', 'datatables.net-rowgroup'], function ( $ ) {
			return factory( $, window, document );
		} );
	}
	else if ( typeof exports === 'object' ) {
		// CommonJS
		module.exports = function (root, $) {
			if ( ! root ) {
				root = window;
			}

			if ( ! $ || ! $.fn.dataTable ) {
				$ = require('datatables.net-dt')(root, $).$;
			}

			if ( ! $.fn.dataTable.RowGroup ) {
				require('datatables.net-rowgroup')(root, $);
			}

			return factory( $, root, root.document );
		};
	}
	else {
		// Browser
		factory( jQuery, window, document );
	}
}(function( $, window, document, undefined ) {

return $.fn.dataTable;

}));
  })();
});